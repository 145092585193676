import React from "react";
import { motion } from "framer-motion";

import Image from "./Image";
import busi from '../assets/busi.jpeg'
import pla from '../assets/pla.jpeg'
import prce from '../assets/prce.jpeg'
import res from '../assets/res.jpeg'
import creative from '../assets/CREATIVE.png'

// Import images

const container = {
    show: {
        transition: {
            staggerChildren: 0.35,
        },
    },
};

const item = {
    hidden: { opacity: 0, y: 200 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "linear",
            duration: 1,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};

const itemMain = {
    hidden: { opacity: 0, y: 200 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "linear",
            duration: 1,
        },
    },
    exit: {
        opacity: 0,
        y: -200,
        transition: {
            ease: "easeInOut",
            duration: 0.8,
        },
    },
};
const Loader = ( { setLoading } ) => {
    return (
        <motion.div className="loader">
            <motion.div
                variants={ container }
                onAnimationComplete={ () => setLoading( false ) }
                initial="hidden"
                animate="show"
                exit="exit"
                className="loader-inner"
            >
                <ImageBlock variants={ item } id={ busi } className="image-1" />
                <motion.div variants={ itemMain } className="transition-image">
                    <motion.img
                        layoutId="main-image-1"
                        src={ creative }
                        className="mobile_imgs"
                    />
                </motion.div>
                <ImageBlock variants={ item } id={ pla } className="image-3" />
                <ImageBlock variants={ item } id={ prce } className="image-4" />
                <ImageBlock variants={ item } id={ res } className="image-5" />
            </motion.div>
        </motion.div>
    );
};

export const ImageBlock = ( { posX, posY, variants, id, className } ) => {
    return (
        <motion.div
            variants={ variants }
            className={ `image-block ${className}` }
            style={ {
                top: `${posY}vh`,
                left: `${posX}vw `,
            } }
        >
            <Image
                src={ id }
                fallback={ id }
                alt={ id }
                className={ `image-block ${className}` }
            />
        </motion.div>
    );
};
export default Loader;
