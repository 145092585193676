import { Container, Fab, Grid } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const GBC = () => {
    const navigate = useNavigate();
    return (
        <div className="max1240">
            <Grid container>
                <Grid item xs={ 12 } md={ 6 }>
                    <Container>
                        <Fab
                            onClick={ () => navigate( -1 ) }
                            variant="extended"
                            sx={ { marginBottom: "10px" } }
                        >
                            <ArrowBackIcon />
                            Home
                        </Fab>
                        <p
                            style={ {
                                color: "#625b5b",
                                fontWeight: "bold",
                                marginBlockEnd: "0px",
                            } }
                        >
                            Website
                        </p>
                        <h2 className="proTitle" style={ { marginTop: "0px" } }>
                            Graphics By Chance
                        </h2>
                        <p
                            style={ {
                                color: "#625b5b",
                                fontWeight: "bold",
                                marginBlockEnd: "0px",
                            } }
                        >
                            Graphics By Chance
                            is a multidisciplinary independently owned
                            design studio focused on creating visuals that
                            are fun, creative and impactful for your brand.
                        </p>
                    </Container>
                </Grid>
                <Grid item xs={ 12 } md={ 6 } sx={ { alignSelf: "end" } }>
                    <Container sx={ { marginTop: "35px" } }>
                        <div className="flexCenterDed">
                            <div
                                style={ {
                                    marginInlineEnd: "15px",
                                    borderBlockStart: "1px solid",
                                } }
                            >
                                <p
                                    style={ {
                                        color: "#000",
                                        fontWeight: "bold",
                                        marginBlockEnd: "0px",
                                    } }
                                >
                                    Client
                                </p>
                                <p
                                    style={ {
                                        color: "#000",
                                        fontWeight: "500",
                                        marginBlock: "0px",
                                    } }
                                >
                                    Zehra Ali
                                </p>
                            </div>
                            <div
                                style={ {
                                    borderBlockStart: "1px solid",
                                } }
                            >
                                <p
                                    style={ {
                                        color: "#000",
                                        fontWeight: "bold",
                                        marginBlockEnd: "0px",
                                    } }
                                >
                                    Tech Stack
                                </p>
                                <p
                                    style={ {
                                        color: "#000",
                                        fontWeight: "500",
                                        marginBlock: "0px",
                                    } }
                                >
                                    NextJS, ReactJS, Tailwind, Framer Motion
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <a
                                className="refLinks flexCenterDed linkButton"
                                href="https://graphicsbychance.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Visit Website <ArrowRightAltIcon />
                            </a>
                        </div>
                    </Container>
                </Grid>
            </Grid>
            <img
                src="/gbc1.png"
                alt="home"
                className="sampSS"
            />
            <img
                src="/gbc2.png"
                alt="home"
                className="wfit"
            />
        </div>
    );
}

export default GBC