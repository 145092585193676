import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

const Hello = ( { view } ) => {
    const targetRef = useRef( null )
    const { scrollYProgress } = useScroll( {
        target: targetRef,
        offset: ['start end', 'end start'],
    } )

    const opacity = useTransform( scrollYProgress, view === 'web' ? [0, 0.8] : [0, 0.6], [0, 1] )
    const scale = useTransform( scrollYProgress, view === 'web' ? [0, 0.8] : [0, 0.6], [2, 1] )
    return (
        <div>
            <motion.div className='goers' ref={ targetRef } style={ { opacity, scale } }>
                <div className="expertTitle">My Work</div>
                <h3 className="workDes">
                    Deployed scalable Ecommerce web apps using React SPA and PWA.
                    Collaborated in 15+ professional projects with clients all around
                    the world.
                </h3>
            </motion.div>
        </div>
    )
}

export default Hello