import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid } from '@mui/material'

const Professional = ( { view } ) => {
    const targetRef = useRef( null )
    const { scrollYProgress } = useScroll( {
        target: targetRef,
        offset: ['start end', 'end start'],
    } )

    const left = useTransform( scrollYProgress, view === 'web' ? [0., 0.5] : [0, 0.38], view === 'web' ? ['240px', '0px'] : ['250px', '0px'] )
    const right = useTransform( scrollYProgress, view === 'web' ? [0., 0.5] : [0, 0.38], view === 'web' ? ['240px', '0px'] : ['250px', '0px'] )
    const bottom = useTransform( scrollYProgress, view === 'web' ? [0., 0.4] : [0, 0.35], view === 'web' ? ['100px', '0px'] : ['70px', '0px'] )
    const stack = ["ReactJS", "NextJS", "JavaScript", "HTML5", "CSS3"];

    return (
        <div
            ref={ targetRef }
            className="experience"
            style={ {
                marginBlockStart: "20px",
                paddingBlockEnd: "30px",
                paddingBlockStart: "50px",

            } }>
            <motion.div style={ { bottom, position: 'relative' } } className="profTitle">Professional Experience</motion.div>
            <Container className="max124">
                <motion.div style={ { position: 'relative', right } }>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={ { backgroundColor: "#eeeeee" } }
                        >
                            <h2 className="accorTitle">
                                Software Developer @House of kalakriti
                            </h2>
                        </AccordionSummary>
                        <AccordionDetails className="accordDet">
                            <Grid container>
                                <Grid item xs={ 9 } sx={ { textAlign: "left" } }>
                                    <div className="descExp">
                                        I am currently working as Lead Software Developer for House of Kalakriti which offers highend range of interior solutions combined with an E-Commerce store of artifacts and services.
                                    </div>
                                    <div className="techStack">
                                        { stack.concat( ['Framer motion'] ).map( ( item, index ) => (
                                            <div className="tech" key={ index }>
                                                { item }
                                            </div>
                                        ) ) }
                                    </div>
                                </Grid>
                                <Grid item xs={ 3 } className="selfCenter">
                                    <img
                                        src="https://hok-staging.vercel.app/hoklogo.svg"
                                        alt="harns"
                                        style={ { maxWidth: '182px' } }
                                        className="logoWall"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </motion.div>
                <motion.div style={ { position: 'relative', left } }>
                    <Accordion sx={ { marginBlock: "10px" } }>
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={ { backgroundColor: "#eeeeee" } }
                        >
                            <h2 className="accorTitle">
                                Software Developer @Harns Technologies
                            </h2>
                        </AccordionSummary>
                        <AccordionDetails className="accordDet">
                            <Grid container>
                                <Grid item xs={ 9 } sx={ { textAlign: "left" } }>
                                    <div className="descExp">
                                        I worked on the leading news agency of India i.e -The Times
                                        of India, NavBharat Times & EPaper Application.Creating a
                                        cutting edge interface for better presentation of valuable
                                        data.
                                    </div>
                                    <div className="techStack">
                                        { stack.map( ( item, index ) => (
                                            <div className="tech" key={ index }>
                                                { item }
                                            </div>
                                        ) ) }
                                    </div>
                                </Grid>
                                <Grid item xs={ 3 } className="selfCenter">
                                    <img
                                        src="https://harnstech.com/NextBigThingimages/harnsWhiteLogo.svg"
                                        alt="harns"
                                        className="logoWall"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </motion.div>
                <motion.div style={ { position: 'relative', right } }>
                    <Accordion sx={ { marginBlock: "10px" } }>
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={ { backgroundColor: "#eeeeee" } }
                        >
                            <h2 className="accorTitle">Frontend Developer @Wallicon</h2>
                        </AccordionSummary>
                        <AccordionDetails className="accordDet">
                            <Grid container>
                                <Grid item xs={ 9 } sx={ { textAlign: "left" } }>
                                    <div className="descExp">
                                        Created frontend of a highly complex B2B & B2C Ecommerce
                                        WebApp with SEO friendly configuration and accommodating
                                        traffic from all over the country.
                                    </div>
                                    <div className="techStack">
                                        { stack.map( ( item, index ) => (
                                            <div
                                                className="tech"
                                                style={ { display: `${index === 1 ? "none" : ""}` } }
                                                key={ index }
                                            >
                                                { item }
                                            </div>
                                        ) ) }
                                    </div>
                                </Grid>
                                <Grid item xs={ 3 } className="selfCenter">
                                    <img
                                        src="https://wallicon.in/assets/images/wallicon_white_logo.png"
                                        alt="wallicon"
                                        className="logoWall"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </motion.div>
                <motion.div style={ { position: 'relative', left } }>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={ <ExpandMoreIcon /> }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={ { backgroundColor: "#eeeeee" } }
                        >
                            <h2 className="accorTitle">Software Developer @Chikan Haul</h2>
                        </AccordionSummary>
                        <AccordionDetails className="accordDet">
                            <Grid container>
                                <Grid item xs={ 9 } sx={ { textAlign: "left" } }>
                                    <div className="descExp">
                                        Developing front-end web app solutions (B2C, B2B) in
                                        Ecommerce Tech on React/Next.js framework.
                                    </div>
                                    <div className="techStack">
                                        { stack.map( ( item, index ) => (
                                            <div className="tech" key={ index }>
                                                { item }
                                            </div>
                                        ) ) }
                                    </div>
                                </Grid>
                                <Grid item xs={ 3 } className="selfCenter">
                                    <img
                                        src="https://chikanhaul.com/logo.png"
                                        alt="harns"
                                        className="logoWall"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </motion.div>
            </Container>
        </div>

    )
}

export default Professional