import { Container, Fab, Grid } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Netflix = () => {
  const navigate = useNavigate();
  return (
    <div className="max1240">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Container>
            <Fab
              onClick={() => navigate(-1)}
              variant="extended"
              sx={{ marginBottom: "10px" }}
            >
              <ArrowBackIcon />
              Home
            </Fab>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              Streaming Platform
            </p>
            <h2 className="proTitle" style={{ marginTop: "0px" }}>
              Netflix Clone
            </h2>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              Wanna Netflix and chill but dont have netflix subscription to
              chill with? i got you covered. this netflix clone is the clone of
              giant streaming platform none as NETFLIX which hosts users from
              all around the globe with huge number of Movies and Web Seriess.
            </p>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
          <Container sx={{ marginTop: "35px" }}>
            <div className="flexCenterDed">
              <div
                style={{
                  marginInlineEnd: "15px",
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Client
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  Self
                </p>
              </div>
              <div
                style={{
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Tech Stack
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  TypeScript,NextJS, Material UI
                </p>
              </div>
            </div>
            <div className="">
              <a
                className="refLinks flexCenterDed"
                href="https://netflix-bice-psi.vercel.app/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Website <ArrowRightAltIcon />
              </a>
            </div>
          </Container>
        </Grid>
      </Grid>
      <img
        src="https://i.ibb.co/MgbtK9V/Screenshot-662.png"
        alt="home"
        className="sampSS"
      />
    </div>
  );
};

export default Netflix;
