import { Container, Grid } from '@mui/material'
import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import JavascriptIcon from "@mui/icons-material/Javascript";
import StorageIcon from "@mui/icons-material/Storage";
import CREATIVE from '../assets/CREATIVE.png'

const Experience = ( { view } ) => {
    const targetRef = useRef( null )
    const { scrollYProgress } = useScroll( {
        target: targetRef,
        offset: ['start end', 'end start'],
    } )

    // const opacity = useTransform( scrollYProgress, [0.5, 0.8], [0, 1] )
    const bottom = useTransform( scrollYProgress, view === 'web' ? [0, 0.4] : [0, 0.35], view === 'web' ? ['100px', '0px'] : ['70px', '0px'] )
    const left = useTransform( scrollYProgress, view === 'web' ? [0, 0.4] : [0, 0.38], view === 'web' ? ['140px', '0px'] : ['250px', '0px'] )
    const top = useTransform( scrollYProgress, view === 'web' ? [0, 0.4] : [0, 0.38], view === 'web' ? ['140px', '0px'] : ['250px', '0px'] )
    const right = useTransform( scrollYProgress, view === 'web' ? [0, 0.4] : [0, 0.38], view === 'web' ? ['140px', '0px'] : ['250px', '0px'] )
    const scale = useTransform( scrollYProgress, view === 'web' ? [0, 0.4] : [0, 0.38], [1.5, 1] )

    return (
        <div>
            <div ref={ targetRef } style={ { paddingBlockStart: "50px", overflow: 'hidden' } }
                className="expertise">
                <motion.div style={ { bottom, position: 'relative' } } className="goers expertTitle">My Expertise</motion.div>
                <Grid
                    container
                    sx={ {
                        padding: "40px 30px",
                        textAlign: "left",
                    } }
                >
                    <Grid
                        item
                        md={ 4 }
                        xs={ 12 }
                        sx={ {} }
                    >
                        <motion.div className="goers" style={ {
                            right, scale, position: 'relative',
                            border: "1px solid", padding: "20px", height: `${view === "mobile" ? "75%" : "85%"}`,
                            marginBlockEnd: `${view === "mobile" && "40px"}`,
                        } }>
                            <Container
                                sx={ {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBlockEnd: "10px",
                                } }
                            >
                                <PersonalVideoIcon
                                    sx={ { marginRight: "10px" } }
                                    className="iconsExp"
                                />
                                <div className="expertDes">
                                    <span className="chonky">Software</span> Development
                                </div>
                            </Container>
                            <Container>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "<h3>" }</span>
                                <div className="descrip">
                                    Experienced in both functional and OOP: JavaScript, TypeScript.
                                </div>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "</h3>" }</span>
                            </Container>
                        </motion.div>
                    </Grid>
                    <Grid
                        item
                        md={ 4 }
                        sx={ {

                        } }
                        xs={ 12 }
                    >
                        <motion.div style={ {
                            top, scale, position: 'relative',
                            border: "1px solid",
                            padding: "20px",
                            height: `${view === "mobile" ? "75%" : "85%"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                        } } ><Container
                            sx={ {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBlockEnd: "10px",
                            } }
                        >
                                <JavascriptIcon
                                    className="iconsExp"
                                    sx={ { marginRight: "10px" } }
                                />
                                <div className="expertDes">
                                    <span className="chonky1">Frontend Dev</span> React, NextJS
                                </div>
                            </Container>
                            { console.log( "year", new Date().getFullYear() - 2020 ) }
                            <Container>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "<h3>" }</span>
                                <div className="descrip">
                                    Passionate about UI/UX. Over { new Date().getFullYear() - 2020 }+ years of development experience
                                    in HTML, CSS, JS, React and NextJS frameworks.
                                </div>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "</h3>" }</span>
                            </Container>
                        </motion.div>
                    </Grid>
                    <Grid
                        item
                        md={ 4 }
                        xs={ 12 }
                    >
                        <motion.div style={ {
                            left, scale, position: 'relative',
                            border: "1px solid",
                            padding: "20px",
                            height: `${view === "mobile" ? "78%" : "85%"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                        } }><Container
                            sx={ {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBlockEnd: "10px",
                            } }
                        >
                                <StorageIcon sx={ { marginRight: "10px" } } className="iconsExp2" />
                                <div className="expertDes">
                                    <span className="chonky2">Backend Dev</span> NodeJS,MongoDB
                                </div>
                            </Container>
                            <Container>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "<h3>" }</span>
                                <div className="descrip">
                                    Knowledge of Backend Libraries and their frameworks like
                                    Express, with professional knowledge of mongoDB and mongoose to
                                    provide full backend support.
                                </div>
                                <span style={ { fontSize: "13px", opacity: 1 } }>{ "</h3>" }</span>
                            </Container>
                        </motion.div>
                    </Grid>
                </Grid>
                <Container sx={ { position: "relative" } }>
                    <div style={ { marginBlock: "40px" } }>
                        <img
                            src={ CREATIVE }
                            alt="bg"
                            className="bgImgCode"
                        />
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Experience