import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

const Contact = ( { view } ) => {
    const targetRef = useRef( null )
    const { scrollYProgress } = useScroll( {
        target: targetRef,
        offset: ['start end', 'end start'],
    } )

    const right = useTransform( scrollYProgress, window.innerWidth > 2000 ? [0, 0.2] : view === 'web' ? [0, 0.5] : [0, 0.3], ['110%', '0%'] )

    return (
        <motion.div ref={ targetRef } style={ { right, maxWidth: "450px", position: 'relative' } }>
            <h1>Available for freelance opportunities</h1>
            <h4
                style={ {
                    marginBlockEnd: "0px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                } }
            >
                Have an exciting project you need help with?
            </h4>
            <h4
                style={ {
                    marginBlockStart: "0px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    marginBlockEnd: "150px",
                } }
            >
                Send me an email or contact me via instant message!
            </h4>
            <a style={ { color: '#000' } } className="chonky3 sans" href="mailto:Akashakhan11@gmail.com">
                Akashakhan11@gmail.com
            </a>
            <div style={ { marginBlock: "10px" } }>
                <a style={ { color: '#000' } }
                    className="refLinks2"
                    href="https://www.instagram.com/codesbyakasha/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Instagram
                </a>
            </div>
            <div style={ { marginBlock: "10px" } }>
                <a style={ { color: '#000' } }
                    className="refLinks2"
                    href="https://www.facebook.com/akashakhan11/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Facebook
                </a>
            </div>
            <div style={ { marginBlock: "10px" } }>
                <a style={ { color: '#000' } }
                    className="refLinks2"
                    href={ "https://wa.me/+91" + 9140246397 }
                    target="_blank"
                    rel="noreferrer"
                >
                    Whatsapp
                </a>
            </div>
        </motion.div>
    )
}

export default Contact