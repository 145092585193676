import { Container, Fab, Grid } from "@mui/material";
import React from "react";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const ChikanHaul = () => {
  const navigate = useNavigate();
  return (
    <div className="max1240">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Container>
            <Fab
              onClick={() => navigate(-1)}
              variant="extended"
              sx={{ marginBottom: "10px" }}
            >
              <ArrowBackIcon />
              Home
            </Fab>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              E-Commerce Web App
            </p>
            <h2 className="proTitle" style={{ marginTop: "0px" }}>
              Chikan Haul (B2C CLOTHING SOLUTION)
            </h2>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              Discover the ultimate online destination for women's fashion at
              our store. Explore a curated collection of chic and trendy
              clothing, from elegant dresses to casual essentials. Elevate your
              style with a diverse range of sizes, colors, and designs, all
              delivered with seamless shopping and exceptional customer service.
              Experience fashion-forward shopping today.
            </p>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
          <Container sx={{ marginTop: "35px" }}>
            <div className="flexCenterDed">
              <div
                style={{
                  marginInlineEnd: "15px",
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Client
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  Mohd Bilal
                </p>
              </div>
              <div
                style={{
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Tech Stack
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  NextJS, Material UI
                </p>
              </div>
            </div>
            {/* <div className="">
              <a
                className="refLinks flexCenterDed"
                href="https://chikanhaul.com/"
                target="_blank"
                rel="noreferrer"
              >
                Open Project <ArrowRightAltIcon />
              </a>
            </div> */}
          </Container>
        </Grid>
      </Grid>
      <img
        src="https://i.ibb.co/HpCpBW5/Screenshot-668.png"
        alt="home"
        className="sampSS"
      />
      <img
        src="https://i.ibb.co/1JFBPv9/Screenshot-670.png"
        alt="home"
        className="wfit"
      />
    </div>
  );
};

export default ChikanHaul;
