import './App.css';
import Home from './components/Home';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import ChikanHaul from './components/ChikanHaul';
import ScrollToTop from './components/ScrollToTop';
import Netflix from './components/Netflix';
import Wallicon from './components/Wallicon';
import CMS from './components/CMS';
import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Loader from './components/Loader';
import HOK from './components/HOK';
import GBC from './components/GBC';
import FriggStudio from './components/FriggStudio';

function App() {
  const [view, setView] = useState( '' )
  const cursor = useRef( null )
  const [loading, setLoading] = useState( true );

  useEffect( () => {
    loading
      ? document.querySelector( "body" ).classList.add( "loading" )
      : document.querySelector( "body" ).classList.remove( "loading" );
  }, [loading] );

  const changePosition = ( e ) => {
    if ( view === 'web' ) {
      cursor.current.style.top = `${e.clientY}px`;
      cursor.current.style.left = `${e.clientX}px`;
    }
  }

  useEffect( () => {
    if ( window.innerWidth < 769 ) {
      setView( 'mobile' )
    }
    else {
      setView( 'web' )
    }
  }, [] )

  return (
    <AnimatePresence>
      { loading ? (
        <motion.div key='loader'>
          <Loader setLoading={ setLoading } />
        </motion.div>
      ) : (
          view.length > 0 &&
          <div className="App" style={ { cursor: `${view === 'web' ? 'none' : 'unsets'}` } } onMouseMove={ changePosition }>
            { view === 'web' && <>
                <div className="cursor-style" ref={ cursor } ></div>
              <div className="cursor-style-outer"  ></div>
            </> }
            <Router>
              <ScrollToTop />
              <Routes>
                <Route exact path="/" element={ <Home view={ view }
                /> } />
                <Route exact path="/chikan-haul" element={ <ChikanHaul /> } />
                <Route exact path="/netflix-clone" element={ <Netflix /> } />
                <Route exact path="/wallicon" element={ <Wallicon /> } />
                <Route exact path="/cms" element={ <CMS /> } />
                  <Route exact path="/house-of-kalakriti" element={ <HOK /> } />
                  <Route exact path="/graphics-by-chance" element={ <GBC /> } />
                  <Route exact path="/frigg-studio" element={ <FriggStudio /> } />
                <Route
                  path="*"
                  element={ <Navigate to="/" replace /> }
                />
              </Routes>
            </Router>
          </div>
      ) }
    </AnimatePresence>
  );
}

export default App;
