import { Container, Fab, Grid } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Wallicon = () => {
  const navigate = useNavigate();
  return (
    <div className="max1240">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Container>
            <Fab
              onClick={() => navigate(-1)}
              variant="extended"
              sx={{ marginBottom: "10px" }}
            >
              <ArrowBackIcon />
              Home
            </Fab>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              E-Commerce Web App
            </p>
            <h2 className="proTitle" style={{ marginTop: "0px" }}>
              Wallicon (B2C & B2B Interior Solution)
            </h2>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              Wallicon is a huge Interior solution platform which performs B2B
              and B2C shopping solution. Highly complex Web App is made to
              satisfy the need of users and dealers from across the country
              making sure the logic remains unbreachable and precise.
            </p>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
          <Container sx={{ marginTop: "35px" }}>
            <div className="flexCenterDed">
              <div
                style={{
                  marginInlineEnd: "15px",
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Client
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  Mohd Imran
                </p>
              </div>
              <div
                style={{
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Tech Stack
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  ReactJS, ReactStrap, BootStrap
                </p>
              </div>
            </div>
            <div className="">
              <a
                className="refLinks flexCenterDed"
                href="https://wallicon.in/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Website <ArrowRightAltIcon />
              </a>
            </div>
          </Container>
        </Grid>
      </Grid>
      <img
        src="https://i.ibb.co/pQrm2tb/Screenshot-664.png"
        alt="home"
        className="sampSS"
      />
      <img
        src="https://i.ibb.co/pv2S7jL/Screenshot-675.png"
        alt="home"
        className="wfit"
      />
    </div>
  );
};

export default Wallicon;
