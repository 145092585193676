import { Container, Grid } from '@mui/material'
import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import Hello from './Hello'

const Expertise = ( { view } ) => {
    const targetRef = useRef( null )
    const navigate = useNavigate();
    const { scrollYProgress } = useScroll( {
        target: targetRef,
        offset: ['start end', 'end start'],
    } )

    const left = useTransform( scrollYProgress, view === 'web' ? [0, 0.25] : [0, 0.2], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const right = useTransform( scrollYProgress, view === 'web' ? [0, 0.25] : [0, 0.2], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const left1 = useTransform( scrollYProgress, view === 'web' ? [0.25, 0.42] : [0.2, 0.4], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const right1 = useTransform( scrollYProgress, view === 'web' ? [0.22, 0.42] : [0.2, 0.4], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const left2 = useTransform( scrollYProgress, view === 'web' ? [0.42, 0.62] : [0.4, 0.6], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const right2 = useTransform( scrollYProgress, view === 'web' ? [0.42, 0.62] : [0.4, 0.6], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )
    const left3 = useTransform( scrollYProgress, view === 'web' ? [0.62, 0.72] : [0.6, 0.8], view === 'web' ? ['100%', '0%'] : ['30%', '0%'] )


    return (
        <div
            className="work"
            style={ { marginBlockStart: "20px", paddingBlockStart: "50px", overflow: 'hidden' } }>
            <Hello view={ view } />
            <Grid
                ref={ targetRef }
                container
                sx={ {
                    padding: "40px 30px",
                    textAlign: "left",
                } }
            >

                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div

                        className='goers'
                        style={ {
                            left,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            paddingInlineEnd: `${view === "web" ? "20px" : "0px"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/house-of-kalakriti" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/hok1.jpeg"
                                alt="hok"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                House Of Kalakriti
                            </div>
                            <div className="proDes">Ecommerce WebApp</div>
                        </Container>
                    </motion.div >
                </Grid>
                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div

                        className='goers'
                        style={ {
                            right,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            paddingInlineEnd: `${view === "web" ? "20px" : "0px"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/graphics-by-chance" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/gbc.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Graphics By Chance
                            </div>
                            <div className="proDes">Website</div>
                        </Container>
                    </motion.div >
                </Grid>
                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div

                        className='goers'
                        style={ {
                            left: left1,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            paddingInlineEnd: `${view === "web" ? "20px" : "0px"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/frigg-studio" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/frigg.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Frigg Studio
                            </div>
                            <div className="proDes">Website</div>
                        </Container>
                    </motion.div >
                </Grid>
                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div

                        className='goers'
                        style={ {
                            right: right1,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            paddingInlineEnd: `${view === "web" ? "20px" : "0px"}`,
                            marginBlockEnd: `${view === "mobile" && "10px"}`,
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/chikan-haul" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/ck.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Chikan Haul
                            </div>
                            <div className="proDes">Ecommerce WebApp</div>
                        </Container>
                    </motion.div >
                </Grid>
                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div
                        className='goers'
                        style={ {
                            left: left2,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/netflix-clone" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/nf.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Netflix Clone
                            </div>
                            <div className="proDes">Streaming Platform</div>
                        </Container>
                    </motion.div>
                </Grid>
                <Grid
                    item
                    md={ 6 }
                    xs={ 12 }
                >
                    <motion.div
                        className='goers'
                        style={ {
                            right: right2,
                            position: 'relative',
                            paddingBlockStart: "15px",
                            paddingInlineEnd: `${view === "web" ? "20px" : "0px"}`,
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/wallicon" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/wi.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Wallicon
                            </div>
                            <div className="proDes">Ecommerce B2B Platform</div>
                        </Container>
                    </motion.div>
                </Grid>
                <Grid
                    item
                    md={ 6 }

                    xs={ 12 }
                >
                    <motion.div
                        className='goers'
                        style={ {
                            left: left3,
                            position: 'relative',
                            padding: "0px",
                            paddingBlockStart: "15px",
                            borderRadius: "5px",
                        } }
                        onClick={ () => navigate( "/cms" ) }
                    >
                        <Container sx={ { padding: "0px !important", overflow: "hidden" } }>
                            <img
                                src="/ad.png"
                                alt="ck"
                                className="ckImg"

                            />
                        </Container>
                        <Container
                            sx={ {
                                padding: "15px",
                                backgroundColor: "#eeeeee",
                            } }
                        >
                            <div
                                style={ {
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBlockEnd: "8px",
                                } }
                            >
                                Content Management System
                            </div>
                            <div className="proDes">For All Ecommerce Platform</div>
                        </Container>
                    </motion.div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Expertise