import { Drawer, Fab, IconButton, Paper } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const headTag = ["home", "expertise", "work", "experience", "contact"];

const Header = ({ home, expertise, work, experience, contact, view }) => {
  const [hoverStyle, setHoverStyle] = useState(5);
  const [headerPos, setHeaderPos] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const someArr = [
    { func: home, name: "home" },
    { func: expertise, name: "expertise" },
    { func: work, name: "work" },
    { func: experience, name: "experience" },
    { func: contact, name: "contact" },
  ];
  const toggleStyle = (index) => {
    setHoverStyle(index);
  };

  function changeCss() {
    const scrollVal = document.documentElement.scrollTop;
    if (scrollVal > 350) {
      setHeaderPos(true);
    } else {
      setHeaderPos(false);
    }
  }

  window.addEventListener("scroll", changeCss);

  const scrollDown = (ref) => {
    const indexOF = someArr.find((d) => d.name === ref);
    if (indexOF) {
      window.scrollTo({
        top: indexOF?.func.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="header posAbs">
        {view !== "mobile" ? (
          <div className={`${headerPos ? "posFix" : "posRelHead"} HeaderTags`}>
            <div className="logoHead" style={{ display: "none" }}>
              <div className="logo-holder logo-6">
                <h3>
                  Akasha <span>Khan</span>
                </h3>
              </div>
            </div>
            {headTag.map((item, index) => (
              <div
                onMouseEnter={() => toggleStyle(index)}
                onMouseLeave={() => toggleStyle(5)}
                onClick={() => scrollDown(item)}
                style={{
                  color: `${
                    headerPos
                      ? hoverStyle < 5
                        ? hoverStyle === index
                        ? "#000"
                          : "#66d9ed80"
                      : "#fff"
                      : hoverStyle < 5
                      ? hoverStyle === index
                        ? "#000"
                        : "#979696"
                      : "#000"
                  }`,
                }}
                className="headTags"
                key={index}
              >
                <span style={{ marginRight: "14px" }}>{"//"}</span>
                <span style={{ marginTop: "5px" }}>{item}</span>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div
              style={{
                textAlign: "start",
                margin: "12px",
                display: "flex",
                position: "fixed",
                top: "0px",
                  zIndex: 99
              }}
            >
              <Fab
                onClick={() => setOpenDrawer(true)}
                variant="extended"
                sx={{ backgroundColor: "transparent" }}
              >
                  <MenuIcon sx={ { color: "#000" } } />
              </Fab>
            </div>
            <Drawer
              anchor={"left"}
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              className="drawerSize"
            >
              <Paper
                sx={{
                  width: "auto",
                  height: "inherit",
                  paddingInlineStart: "15px",
                  boxShadow: "unset",
                  minWidth: "50vh",
                  backgroundColor: "#efefef",
                }}
                id="test"
              >
                <IconButton
                  sx={{
                    justifyContent: "flex-end",
                    marginBlockStart: "20px",
                    marginBlockEnd: "50px",
                  }}
                  onClick={() => setOpenDrawer(false)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                {headTag.map((item, index) => (
                  <div
                    onMouseEnter={() => toggleStyle(index)}
                    onMouseLeave={() => toggleStyle(5)}
                    onClick={() => {
                      scrollDown(item);
                      setOpenDrawer(false);
                    }}
                    style={{
                      color: "#000",
                    }}
                    className="headTags"
                    key={index}
                  >
                    <span style={{ marginRight: "5px" }}>{"//"}</span>
                    <span style={{ marginTop: "5px" }}>{item}</span>
                  </div>
                ) ) }
              </Paper>
            </Drawer>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
