import { Container, Fab, Grid } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const CMS = () => {
  const navigate = useNavigate();
  return (
    <div className="max1240">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Container>
            <Fab
              onClick={() => navigate(-1)}
              variant="extended"
              sx={{ marginBottom: "10px" }}
            >
              <ArrowBackIcon />
              Home
            </Fab>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              E-Commerce Web App
            </p>
            <h2 className="proTitle" style={{ marginTop: "0px" }}>
              Content Management System
            </h2>
            <p
              style={{
                color: "#625b5b",
                fontWeight: "bold",
                marginBlockEnd: "0px",
              }}
            >
              Streamline website management with our intuitive Content
              Management System (CMS). Effortlessly create, edit, and organize
              digital content using a user-friendly interface. Enhance
              collaboration with multi-user access and permission controls.
              Boost efficiency with customizable templates and seamless
              publishing, empowering you to deliver dynamic and engaging online
              experiences effortlessly.
            </p>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
          <Container sx={{ marginTop: "35px" }}>
            <div className="flexCenterDed">
              <div
                style={{
                  marginInlineEnd: "15px",
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Client
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  Self
                </p>
              </div>
              <div
                style={{
                  borderBlockStart: "1px solid",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    marginBlockEnd: "0px",
                  }}
                >
                  Tech Stack
                </p>
                <p
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    marginBlock: "0px",
                  }}
                >
                  ReactJS, ReactStrap, BootStrap
                </p>
              </div>
            </div>
            <div className="">
              <a
                className="refLinks flexCenterDed"
                href="https://admin.chikanhaul.com/"
                target="_blank"
                rel="noreferrer"
              >
                Visit Website <ArrowRightAltIcon />
              </a>
            </div>
          </Container>
        </Grid>
      </Grid>
      <img
        src="https://i.ibb.co/7t1Ntcw/Screenshot-666.png"
        alt="home"
        className="sampSS"
      />
    </div>
  );
};

export default CMS;
