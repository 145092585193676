import {
  Grid,
} from "@mui/material";
import React, { useRef } from "react";
import Header from "./Header";
import { motion, useScroll, useTransform } from 'framer-motion'
import Experience from "./Experience";
import Expertise from "./Expertise";
import Professional from "./Professional";
import Contact from "./Contact";
import HomeV from '../assets/Video.mp4'


const Home = ( { view } ) => {
  const home = useRef( null );
  const expertise = useRef( null );
  const work = useRef( null );
  const experience = useRef( null );
  const contact = useRef( null );
  const targetRef = useRef( null )
  const { scrollYProgress } = useScroll( {
    target: targetRef,
    offset: ['end end', 'end start'],
  } )

  const opacity = useTransform( scrollYProgress, view === 'web' ? [0.5, 1] : [0.3, 0.8], [1, 0] )
  const top = useTransform( scrollYProgress, view === 'web' ? [0.4, 1] : [0.4, 1], view === 'web' ? ['50%', '20%'] : ['50%', '20%'] )
  const filter = useTransform( scrollYProgress, ( pos ) => { return pos > 0.6 ? `blur(5px)` : 'blur(0px)' } )

  return (
    <React.Fragment>
      <Header
        home={ home }
        expertise={ expertise }
        work={ work }
        experience={ experience }
        contact={ contact }
        view={ view }
      />
      <div>
        <div ref={ home }>
          <div style={ {} } className="Home">
            <div className="homeBg">
              { view === "web" ?
                // <Grid container sx={ { alignItems: 'center' } }>
                //   <Grid item xs={ 12 } md={ 12 } >
                <div style={ { height: '100vh' } }>
                      <motion.div ref={ targetRef } style={ { opacity, position: 'relative', top, textAlign: '-webkit-center' } }>
                        <div className="titleDes">
                          SOFTWARE ENGINEER & FRONTEND DEVELOPER
                        </div>
                        <div className="homeTitle">AKASHA KHAN</div>
                      </motion.div>
                    </div>
                //    </Grid>
                // </Grid> 
                :
                <div style={ { height: '100vh' } }>
                  <motion.div ref={ targetRef } style={ { filter, opacity, position: 'relative', top: '50%', textAlign: '-webkit-center' } }>
                    <div className="titleDes">
                      SOFTWARE ENGINEER & FRONTEND DEVELOPER
                    </div>
                    <div className="homeTitle">AKASHA KHAN</div>
                  </motion.div>
                </div>
                //                 <Grid container sx={ { alignItems: 'center' } }>
                //                   <Grid item xs={ 12 } md={ 6 }>
                //                     <div className="VideoTag"
                //                       dangerouslySetInnerHTML={ {
                //                         __html: `<video class="VideoTag" autoplay loop muted playsinline>
                //       <source src=${HomeV} type="video/mp4" />
                //       Your browser does not support the video tag.
                // </video>`,
                //                       } }
                //                     />
                //                   </Grid>
                //                   <Grid item xs={ 12 } md={ 6 } >
                //                     <div>
                //                       <motion.div ref={ targetRef } style={ { filter, opacity, position: 'relative', top: '-30px', textAlign: '-webkit-center' } }>
                //                         <div className="titleDes">
                //                           SOFTWARE ENGINEER & FRONTEND DEVELOPER
                //                         </div>
                //                         <div className="homeTitle">AKASHA KHAN</div>
                //                       </motion.div>
                //                     </div>
                //                   </Grid>
                //                 </Grid>
              }
            </div>
          </div>
        </div>
        <div ref={ expertise }>
          <Experience view={ view } />
        </div>
        <div ref={ work } style={ { overflow: 'hidden' } }>
          <Expertise view={ view } />
        </div>
        <div ref={ experience } style={ { overflow: 'hidden' } }>
          <Professional view={ view } />
        </div>
        <div ref={ contact }>
          <div style={ { paddingBlockStart: "50px", } }
            className="contact">
            <Grid container sx={ { backgroundColor: '#159f4b' } }>
              <Grid sx={ { zIndex: 10 } } item xs={ 12 } md={ 6 }>
                <div>
                  <video className='VideoTag' autoPlay loop muted>
                    <source src={ HomeV } type='video/mp4' />
                  </video>
                </div>
                {/* <Grid container>
                  <Grid
                    item
                    xs={ 12 }
                    md={ 6 }
                    sx={ {
                      padding: "30px",
                      backgroundColor: "#3eb489",
                      textAlign: "left",
                    } }
                  >
                    <div style={ { textAlign: "-webkit-right" } }>
                      <div className="initials">I</div>
                    </div>
                    <p className="testi">
                      Since 2021 Akasha has been responsible for the development of
                      our website which has been instrumental to the growth of our
                      company. Even while working remotely he’s been highly
                      responsive, organized and strategic in his thinking. In
                      addition to staying on top of day-to-day site changes and
                      builds, he’s provided us with great advice to stay on top of
                      the current changes in web technologies. He’s also implemented
                      effective project management and Q&A processes. As a result
                      Akasha has been an highly valued and impactful member of our
                      team.
                    </p>
                    <div style={ { fontWeight: "bold" } }>-Mr. Mohd Imran</div>{ " " }
                    <div
                      style={ {
                        color: "inherit",
                        fontSize: "15px",
                        fontWeight: "500",
                        lineHeight: "inherit",
                      } }
                    >
                      { " " }
                      Founder of Wallicon
                    </div>
                  </Grid>
                  <Grid item xs={ 12 } md={ 6 }>
                    <div
                      style={ {
                        padding: "30px",
                        backgroundColor: "#f7cac9",
                        textAlign: "left",
                      } }
                    >
                      <div style={ { textAlign: "-webkit-right" } }>
                        <div className="initials">B</div>
                      </div>
                      <p className="testi">
                        He delivered way more than what we asked for and that really adds a star to his credibility.
                      </p>
                      <div style={ { fontWeight: "bold" } }>-Mr. Mohd Bilal</div>{ " " }
                      <div
                        style={ {
                          color: "inherit",
                          fontSize: "15px",
                          fontWeight: "500",
                          lineHeight: "inherit",
                        } }
                      >
                        { " " }
                        Founder Chikan Haul
                      </div>
                    </div>
                    <div
                      style={ {
                        padding: "30px",
                        backgroundColor: "#c8a2c8",
                        textAlign: "left",
                      } }
                    >
                      <div style={ { textAlign: "-webkit-right" } }>
                        <div className="initials">S</div>
                      </div>
                      <p className="testi">
                        Akasha is one of the best professionals that we have known
                        in web development skills. Between his skills you can find
                        good communication and accuracy with the planning in complex
                        projects.
                      </p>
                      <div style={ { fontWeight: "bold" } }>-Mr. Sandeep Singh</div>{ " " }
                      <div
                        style={ {
                          color: "inherit",
                          fontSize: "15px",
                          fontWeight: "500",
                          lineHeight: "inherit",
                        } }
                      >
                        { " " }
                        Team Lead @Wallicon 2022
                      </div>
                    </div>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid
                item
                xs={ 12 }
                md={ 6 }
                sx={ { padding: "50px", color: '#000', textAlign: "left" } }
              >
                <Contact view={ view } />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
